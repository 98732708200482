<template>
    <!-- form -->
    <form class="validate-form">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Add new question</h4>
            <base-card-drop-down>
                <template #title>
                    Question options
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Multiple choice question
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Open ended question
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Questions for oral accessment
                    </a>
                </template>
            </base-card-drop-down>
        </template>
        <template #default>
                <div class="row">
                    <!-- header section -->
                    <div class="d-flex">
                        <div class="row">
                            <div class="col-2 col-lg-2 col-sm-12"></div>
                            <div class="col-8 col-lg-8 col-sm-12">
                                <div class="card btn-outline-secondary">
                                    <base-image
                                         imgName="04.jpg"
                                         imgGeo="slider"
                                         imgAlt="Card image cap"
                                         imgClass="card-img-top"
                                         >
                                     </base-image>
                                    <div class="card-body">
                                        <h4 class="card-title">File title</h4>
                                        <label for="account-upload" class="btn btn-sm btn-outline-secondary mb-75 me-75">Upload</label>
                                        <input type="file" id="account-upload" hidden accept="image/*" />
                                        <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 col-lg-2 col-sm-12"></div>
                        </div>
                    </div>
                    <!--/ header section -->

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">
                                Question | Include
                                <span class="text-primary"> media file  </span> or
                                <span class="text-primary"> link to video </span>
                            </label>
                            <textarea class="form-control" id="accountTextarea" rows="2" placeholder="Enter question"></textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">
                                Enter video link
                            </label>
                            <textarea class="form-control" id="accountTextarea" rows="2" placeholder="Enter question"></textarea>
                        </div>
                    </div>




                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="basicSelect">Select Category | <span class="text-primary">Register new category. </span> </label>
                            <select class="form-select" id="basicSelect">
                                <option>Select</option>
                                <option>Register new category</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 mt-2">
                        <div class="mb-2">
                            <label class="form-label" for="account-birth-date">Category name</label>
                            <input type="text" class="form-control" placeholder="Organization name" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">Category description</label>
                            <textarea class="form-control" id="accountTextarea" rows="2" placeholder="Enter question"></textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="basicSelect">Answer format</label>
                            <select class="form-select" id="basicSelect">
                                <option>Single answer from multiple choice</option>
                                <option>Multiple answer from multiple choice</option>
                                <option>Open ended</option>
                                <option>Upload file</option>
                            </select>
                        </div>
                    </div>



                    <div class="col-12 col-sm-12 mb-1 mt-1">
                            <label class="form-label" for="basicSelect">Answer Options </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Add areas of interest" aria-describedby="button-addon2" />
                                <button class="btn btn-outline-primary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>
                            <div class="mt-1">
                                <base-list-tag tagColor="secondary" :tagIcon="false" >
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                        <label class="form-check-label" for="inlineRadio2">Unchecked</label>
                                    </div>
                                </base-list-tag>
                            </div>
                            <div class="mt-1">
                                <base-list-tag tagColor="secondary" :tagIcon="false" >
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                        <label class="form-check-label" for="inlineRadio2">Unchecked</label>
                                    </div>
                                </base-list-tag>
                            </div>
                            <div class="mt-1">
                                <base-list-tag tagColor="secondary" :tagIcon="false" >
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                        <label class="form-check-label" for="inlineRadio2">Unchecked</label>
                                    </div>
                                </base-list-tag>
                            </div>
                            <div class="mt-1">
                                <base-list-tag tagColor="secondary" :tagIcon="false" >
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                        <label class="form-check-label" for="inlineRadio2">Unchecked</label>
                                    </div>
                                </base-list-tag>
                            </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">Answer</label>
                            <textarea class="form-control" id="accountTextarea" rows="8" placeholder="Enter question"></textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label for="account-upload" class="btn btn-sm btn-outline-secondary mb-75 me-75">Upload</label>
                            <input type="file" id="account-upload" hidden accept="image/*" />
                            <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>

                        </div>
                    </div>

                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox1">Image</label>
                            </div>
                        </base-list-tag>
                    </div>
                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox2">Pdf</label>
                            </div>
                        </base-list-tag>
                    </div>
                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox3">Power point</label>
                            </div>
                        </base-list-tag>
                    </div>
                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox4">Video</label>
                            </div>
                        </base-list-tag>
                    </div>
                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox3">GIF</label>
                            </div>
                        </base-list-tag>
                    </div>
                    <div class="mt-1 col-6 col-lg-6 col-md-6 col-12-6">
                        <base-list-tag tagColor="secondary" :tagIcon="false" >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="checked"  />
                                <label class="form-check-label" for="inlineCheckbox4">Audio</label>
                            </div>
                        </base-list-tag>
                    </div>




                    <div class="col-12">
                        <base-button btnColor="primary" >Save question</base-button>
                        <base-button btnColor="primary" btnType="outline" >Preview</base-button>
                    </div>
                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->

    </form>
    <!--/ form -->
</template>

<script>


export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    }
}


</script>
